// import Home from '../components/Home';
import dynamic from 'next/dynamic';

const Home = dynamic(() => import('@/components/Home'));
import Layout from '@/components/Layout';
// const Layout = dynamic(()=> import('@/components/Layout'))
Home.getLayout = function getLayout(page) {
  return (
    <Layout webViewMobile={true} web="home">
      {page}
    </Layout>
  );
};

export async function getServerSideProps(context) {
  const UA = context.req.headers['user-agent'];
  const isMobile = Boolean(
    UA.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i,
    ),
  );
  try {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_SERVER_URL}/app/slide_home/get-list-slide-home`,
      {
        headers: {
          'Content-Type': 'application/json',
          locale: context.locale,
        },
      },
    );
    const data = await res.json();
    if (!data || data.code !== 200) {
      return {
        notFound: true,
      };
    }
    return {
      props: { ...data.data, sign: context.locale }, // will be passed to the page component as props
    };
  } catch (error) {
    return {
      notFound: true,
    };
  }
}

export default Home;
